import { Component, OnInit, ViewChild, Inject, ElementRef, TemplateRef, HostBinding } from '@angular/core';
import { MatDialog } from '@angular/material';

import { localStorageToken, sessionStorageToken } from '../providers';
import { LoginService } from '../login/login.service';
import { VERSION } from '../providers';
import { InternalService } from './internal.service';
import { BugReportDialogComponent } from './bug-report-dialog/bug-report-dialog.component';
import { TicketListComponent } from './ticket-list-dialog/ticket-list-dialog.component';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ReleaseNotesDialogComponent } from './release-notes-dialog/release-notes-dialog.component';
import { DevicesService } from '../devices/devices.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-internal',
	templateUrl: './internal.component.html',
	styleUrls: ['./internal.component.scss']
})
export class InternalComponent implements OnInit {
	
	version = VERSION;
	teams;
	companies: any[];
	customStyle = {logo:'',background:''};
	@ViewChild('teamModal') teamModel: ElementRef;
	@ViewChild('companyModal') companyModel: ElementRef;
	
	@HostBinding('style.background')
	background: String = '';
	
	constructor(
		private router: Router,
		public loginService: LoginService,
		private deviceService: DevicesService,
		private internalService: InternalService,
		private dialog: MatDialog,
		private toastr: ToastrService,
		@Inject(localStorageToken) private localStorage: any,
		@Inject(sessionStorageToken) private sessionStorage: any
	) { }
	
	ngOnInit() {
		console.log('Internal component',this.loginService.user)

		this.companies = [];
		if (this.loginService.user.team.style && this.loginService.user.team.style.logo){
			this.customStyle.logo = this.loginService.user.team.style.logo
		} else if(this.loginService.user.team.company.style && this.loginService.user.team.company.style.logo){
			this.customStyle.logo = this.loginService.user.team.company.style.logo
		}
		
		if (this.loginService.user.team.style && this.loginService.user.team.style.background){
			this.customStyle.background = this.loginService.user.team.style.background;
			this.background = this.customStyle.background;
		} else	if (this.loginService.user.team.company.style && this.loginService.user.team.company.style.background) {
			this.customStyle.background = this.loginService.user.team.company.style.background;
			this.background = this.customStyle.background;
		}

		//loaddevices
		this.deviceService.getDevices().subscribe(async resp => {
			this.deviceService.devices = await resp.json();
		})
	}

	logout() {
		localStorage.removeItem('Auth-Token');
		sessionStorage.removeItem('Auth-Token');
		this.loginService.isLoggedIn = false;
		location.reload();
	}
	
	openBugReportDialog() {
		this.dialog.open(BugReportDialogComponent, { width: '800px' });
	}
	
	openTktDialog() {
		this.dialog.open(TicketListComponent, { width: '800px' });
	}
	
	openTeamModal(modal) {
		modal.style.display = 'block';
		this.loadTeams();
	}
	
	async openCompanyModal(modal) {
		modal.style.display = 'block';
		this.companies = (await this.internalService.loadCompanies().toPromise()).json();
	}
	
	changeCompany(companyId) {
		this.internalService
		.changeCompany(companyId)
		.subscribe(res => {
			this.loginService.user = res.json();
			location.reload();
		},
		err => {
			this.toastr.error('Erro ao trocar de company');
			console.log(err)
		});
	}

	closeDialogOnOutsideClick(event: MouseEvent, modal) {
        if (event.target === modal) {
            modal.style.display = 'none';
        }
    }
	
	loadTeams() {
		this.internalService
		.listTeamsOfCompany()
		.subscribe(res => {
			this.teams = res.json();
			
			if(!this.loginService.isAdmin()){
				this.teams = this.teams.filter((t) => t.allowedUsers.indexOf(this.loginService.user._id) > -1);
			}
		},
		err => console.log(err)
		);
	}
	
	changeTeam(teamId) {
		this.internalService
		.changeTeam(teamId)
		.subscribe(res => {
			this.loginService.user = res.json();
			// this.closeTeamModal(this.teamModel.nativeElement);
			location.reload();
		},
			err => console.log(err)
		);
	}
		
	adminAccount() {
		this.router.navigate(['admin']);
	}
	
	gotoSuperAdmin() {
		this.router.navigate(['superadmin']);
	}
	
	adminShowcase() {
		this.router.navigate(['admin/showcases']);
	}
	
	adminPendingDevices() {
		this.router.navigate(['admin/pending-devices']);
	}
	
	openReleaseNotes(){
		this.dialog.open(ReleaseNotesDialogComponent, {
			width: '40%',
			height: '400px',
			data: {}
		})
	}			
}
