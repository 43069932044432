import { Injectable, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVER } from '../providers';

@Injectable()
export class AppsService {

  constructor(
    private http: Http,
  ) {
  }

  listApps() {
    return this.http.get(`${URL_SERVER}/services/resource/app/list`);
  }
  
  listImages() {
    return this.http.post(`${URL_SERVER}/services/resource/app/listImages`, {});
  }
  listDocs() {
    return this.http.post(`${URL_SERVER}/services/resource/app/listDocuments`, {});
  }

  renameApp(app) {
    return this.http.post(`${URL_SERVER}/services/resource/app/rename`, { appId: app._id, name: app.name });
  }

  delete(appId: string) {
    return this.http.delete(`${URL_SERVER}/services/resource/app/delete/${appId}`);
  }
  
  deleteResource(resId: string) {
    return this.http.delete(`${URL_SERVER}/services/resource/app/deleteResource/${resId}`);
  }
  
  renameResource(resource){
    return this.http.post(`${URL_SERVER}/services/resource/app/renameResource/`, {resource});
  }

}
