import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { DISABLED } from '@angular/forms/src/model';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { MFADialogComponent } from './MFADialog/mfa-dialog.component';

@Component({
	selector: 'app-configuration',
	templateUrl: './configuration.component.html',
	styleUrls: ['./configuration.component.scss']
})

export class ConfigurationComponent implements OnInit {

	almForm: FormGroup;
	configForm: FormGroup;
	slackForm: FormGroup;
	integration: any;
	config: any;
	loggedUser: any;

	constructor(
		private configurationServce: ConfigurationService,
		private toastr: ToastrService,
		private router: Router,
		private fb: FormBuilder,
		public loginService: LoginService,
		private dialog: MatDialog,
	) { }

	ngOnInit() {
		this.integration = { alm: {}, slack: {} };
		this.config = { user: {} };
		this.config.user = { ...this.loginService.user };
		this.config.user.mfa = this.config.user.mfa ? this.config.user.mfa : { active: false, secret: '' };

		this.configForm = new FormGroup({
			firstname: new FormControl(''),
			lastname: new FormControl(''),
			password: new FormControl({ value: '', type: 'password' }),
			confirmpassword: new FormControl({ value: '', type: 'password' }),
			email: new FormControl(''),
			username: new FormControl({ value: '', disabled: true }),
			userapikey: new FormControl({ value: '', disabled: true })
		});
		this.slackForm = new FormGroup({
			token: new FormControl({ value: '', disabled: true }, Validators.required),
			channelId: new FormControl({ value: '', disabled: true }, Validators.required)
		});

		this.configurationServce.getCompanyIntegration().subscribe(async resp => {
			this.integration = await resp.json();
		});
	}

	copy(text) {
		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = text;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);

		this.toastr.success('Copiado!');
	}

	almStatusChange(e: any) {
		if (e.checked) {
			this.almForm.controls.domain.enable();
			this.almForm.controls.host.enable();
			this.almForm.controls.username.enable();
			this.almForm.controls.password.enable();
		} else {
			this.almForm.controls.domain.disable();
			this.almForm.controls.host.disable();
			this.almForm.controls.username.disable();
			this.almForm.controls.password.disable();
		}
	}

	async toggleMFA() {
		let dialogRef;
		if (!this.config.user.mfa.active) {
			this.loginService.createMFAToken()
				.subscribe(async mfaInfo => {
					dialogRef = this.dialog.open(MFADialogComponent, {
						width: '460px',
						data: await mfaInfo.json()
					});

					dialogRef.afterClosed().subscribe(async result => {
						if (result) {
							this.config.user.mfa.active = true;
						}
					});
				})
		} else {
			//se já ativo, para cancelar tem que informar o token
			dialogRef = this.dialog.open(MFADialogComponent);

			dialogRef.afterClosed().subscribe(async result => {
				if (result) {
					this.config.user.mfa.active = false;
				}
			});
		}

	}

	slackStatusChange(e: any) {
		this.integration.slack.enabled = !this.integration.slack.enabled;

		if (e.checked) {
			this.slackForm.controls.token.enable();
			this.slackForm.controls.channelId.enable();
		} else {
			this.slackForm.controls.token.disable();
			this.slackForm.controls.channelId.disable();
		}
	}
	updateAlm() {
		//console.log(f);
	}

	updateSlack() {
		this.configurationServce
			.updateIntegration(this.integration)
			.subscribe(resp => {
				this.toastr.success('Configuração atualizada!', '', { closeButton: true });
			},
				err => {
					console.log(err);
				});
	}

	getErrorMessageDomain() {
		return this.almForm.controls.domain.hasError('required') ? 'O campo é obrigatório' :
			this.almForm.controls.domain.hasError('domain') ? 'O campo não é válido' : '';
	}

	getErrorMessageHost() {
		return this.almForm.controls.host.hasError('required') ? 'O campo é obrigatório' :
			this.almForm.controls.host.hasError('host') ? 'O campo não é válido' : '';
	}

	getErrorMessageUsername() {
		return this.almForm.controls.username.hasError('required') ? 'O campo é obrigatório' :
			this.almForm.controls.username.hasError('username') ? 'O campo não é válido' : '';
	}

	getErrorMessageaPassword() {
		return this.almForm.controls.password.hasError('required') ? 'O campo é obrigatório' :
			this.almForm.controls.password.hasError('password') ? 'O campo não é válido' : '';
	}

	btnTest() {
		let valid = true;

		const showSucess = this.almForm.valid;
		const showFail = this.almForm.invalid;

		/*let controlDomain = this.almForm.controls.domain.valid == true;
		let controlHost = this.almForm.controls.host.valid == true;
		let controlUsername = this.almForm.controls.username.valid == true;
		let controlPassword = this.almForm.controls.password.valid == true;*/


		if (showSucess && !showFail) {
			valid = true;
		} else if (!showSucess) {
			valid = false;
		}

	}

	updateConfig() {
		if(this.config.user.password === undefined && this.config.user.confirmpassword === undefined){
			this.config.user.password = ``;
			this.config.user.confirmpassword = ``;
		}

		if (this.config.user.password === this.config.user.confirmpassword && (this.config.user.password.length > 5 || this.config.user.password.length == 0)) {
			this.loginService.updateUser(this.config.user).subscribe(async resp => {
				this.config.user.password = '';
				this.config.user.confirmpassword = '';

				this.toastr.success('Perfil atualizado!', '', { closeButton: true });
			});
		} else {
			this.toastr.warning('Formulário inválido.', '', { closeButton: true });
		}
	}

}
