import { Component, EventEmitter, Input, OnInit, ViewChild, Output } from '@angular/core';
import { Observable, Observer } from 'rxjs';//possivel troca pra 'rxjs'
import { LoginService } from '../../login/login.service';
import { ConnectService } from '../../connect/connect.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-util-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.scss']
})
export class UploadComponent {

	uploadProgress: any[] = [];
	@Output() uploadEvent: EventEmitter<any> = new EventEmitter();
	@Input() params: any;
	seq: any = 0;

	formats() {
		if (!this.params || !this.params.type) {
			return '*'; // Permite todos os arquivos
		  }
		
		  const type = this.params.type.toLowerCase();
		
		  switch (type) {
			case 'img':
			case 'image':
			  return 'image/*'; // Qualquer tipo de imagem
			case 'document':
			  return '.pdf,.doc,.docx'; // Exemplo de documentos suportados
			case 'android':
			  return '.apk'; // Apenas APKs
			case 'ios':
			  return '.ipa'; // Apenas IPAs
			default:
			  return '*'; // Caso padrão: permite todos os arquivos
		  }
	}

	constructor(
		private connectService: ConnectService,
		private toastr: ToastrService
	) { }

	onChoose(event: any) {
		//travar timeout aqui
		if (this.connectService.activeDevice) {
			// const index = this.connectService.activeDevices.findIndex(itDevice => itDevice.device._id === this.connectService.activeDevice.device._id);
			// clearTimeout(this.connectService.activeDevices[index].timeoutMsg);
			// clearTimeout(this.connectService.activeDevices[index].timeoutDisc);
			clearTimeout(this.connectService.activeDevice.timeoutMsg);
			clearTimeout(this.connectService.activeDevice.timeoutDisc);
		}

		for (let i = 0; i < event.srcElement.files.length; i++) {
			const file = event.srcElement.files[i];
			if (file) {
				console.log(file)
				const uploadRequest = {
					value: null as UploadEntry,
					observable: null as Observable<UploadEntry>,
					observer: null as Observer<UploadEntry>,
				};

				uploadRequest.observable = Observable.create((observer: Observer<UploadEntry>) => {
					uploadRequest.observer = observer;
				})/*.share()*/.subscribe((data: any) => {
					//console.log('progress = ', data);
				});

				this.seq += 1;

				uploadRequest.value = {
					id: this.seq,
					progress: 0,
					file: event.srcElement.files[i]
				};

				this.uploadProgress.push(uploadRequest);

				this.makeFileRequest('/services/resource/app/upload', this.params, file, uploadRequest).subscribe((val: any) => {
					this.uploadEvent.emit(val);

					const index = this.uploadProgress.findIndex((up) => up.id === uploadRequest.value.id);
					this.uploadProgress.splice(index, 1);
				},
					(error: any) => {
						this.uploadProgress = [];
						this.toastr.error('Falha ao subir arquivo.');
					});
			}
		}
	}

	makeFileRequest(url: string, params: any, file: any, uploadRequest: any): Observable<any> {
		return Observable.create((observer: Observer<any>) => {
			const formData: FormData = new FormData();
			const xhr: XMLHttpRequest = new XMLHttpRequest();

			Object.keys(params).forEach((k) => {
				formData.append(k, params[k]);
			});
			formData.append('file', file, file.name);

			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						observer.next(JSON.parse(xhr.response));
						observer.complete();
					} else {
						this.toastr.error('Falha ao subir arquivo.');
						observer.error(xhr.response);
					}
				}
			};

			xhr.timeout = 20000 * 60 * 10;

			xhr.upload.onprogress = (event) => {
				uploadRequest.value.progress = Math.round(event.loaded / event.total * 100);
				uploadRequest.observer.next(uploadRequest.value);
			};

			xhr.open('POST', url, true);
			xhr.setRequestHeader('Auth-Token', LoginService.authToken().toString());
			xhr.send(formData);
		});
	}
}

class UploadEntry {
	id: Number;
	file: string;
	progress: number;
}
