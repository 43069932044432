import { OnInit, OnDestroy, Component, ViewChild, ElementRef } from "@angular/core";
import { LoginService } from "../../login/login.service";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AccountsService } from "./accounts.service";
import { DevicesService } from './../../devices/devices.service';
import { EditAccountDialogComponent } from './edit-account-dialog/edit-account-dialog.component';
import { SaveDeviceDialogComponent } from './save-device-dialog/save-device-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { EditTeamDialogComponent } from './edit-team-dialog/edit-team-dialog.component';
import { UploadComponent } from './../../util/upload/upload.component';
import { ActionDialogComponent } from "./action-dialog/action-dialog.component";
import * as Papa from 'papaparse';

@Component({
    selector: 'app-account',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss']
})

export class AccountsComponent implements OnInit, OnDestroy {

	@ViewChild('uplogo') uploadComp: UploadComponent;
    @ViewChild('detailModal') detailModal: ElementRef;
    uploadSubscr: any;
    pendingDevices: any[];
    accountSub: any;
    actSub: any;
    selectedUser: any;
    devicesSub: any;
    devices: any;
    accFilter:any;
    devFilter:any;
    rawDatail: any;
    teamFilter:any;
    delSub: any;
    loggedUser: any;
    accounts: any[];
    actions: any[];
    teams: any[];
    trackingLogs: any[];
    style: any;

    minDate: Date = new Date(2000, 0, 1);
    maxDate: Date = new Date(2120, 0, 1);
    filterFrom: Date;
	filterTo: Date;
    startHour: any[];
    endHour: any[];
    selectedStartHour: any;
    selectedEndHour: any;

    constructor(
		private router: Router,
        private loginService: LoginService,
        private devicesService: DevicesService,
        private toastr: ToastrService,
        private accountService: AccountsService,
		private dialog: MatDialog,
	) {
        const currentDate: Date = new Date();
		this.minDate = new Date(currentDate.getTime() - (180 * 24 * 60 * 60 * 1000));
		this.maxDate = new Date(currentDate.setHours(23,59,59));
		
        this.filterTo = new Date(currentDate);
        this.filterTo.toLocaleDateString();
        this.filterFrom = new Date(currentDate.getTime() - (60 * 24 * 60 * 60 * 1000));
        this.filterFrom.toLocaleDateString();
	}

    ngOnInit(): void {
        this.rawDatail = '';
        this.accounts = [];
        this.style = {logo:'', background:''};
        this.trackingLogs = [];
        this.selectedStartHour = '00'
        this.selectedEndHour = '23'
        this.startHour = []
        this.endHour = []
        for (let i = 0; i < 24; i++) {
            this.startHour.push(`${i.toLocaleString('en-US',{ minimumIntegerDigits: 2, useGrouping: false })}`);
            this.endHour.push(`${i.toLocaleString('en-US',{ minimumIntegerDigits: 2, useGrouping: false })}`);
        }

        if (this.isAdmin()) {

            this.findUsersNTeams();
            this.findDevices();
            this.findActions();

            setTimeout(() => {
                this.uploadSubscr = this.uploadComp.uploadEvent.subscribe((val: any) => {
                    this.style.logo = val.url;
                });
            }, 1000);

        } else {
            this.toastr.error('Você não é um admin!');
            this.router.navigate(['/']);
        }
    }

    findUsersNTeams(){
        this.accountSub = this.accountService.listAccounts(this.isRoot()).subscribe(async resp => {
            let data = await resp.json();
            this.accounts = data.accounts;
            this.teams = data.teams;
        });
    }

    findDevices(){
        this.devicesSub = this.devicesService.listByCompany().subscribe(async resp => {
            let data = await resp.json();
            this.devices = data;
        });
    }

    findActions(){
        this.actSub = this.accountService.listActions().subscribe(async resp => {
            let data = await resp.json();
            this.actions = data;
        });
    }

    isAdmin(){
        return this.loginService.user.roles && (this.loginService.user.roles.indexOf('ADMIN') > -1 || this.loginService.user.roles.indexOf('ROOT') > -1)
    }

    isRoot(){
        return this.loginService.user.roles && (this.loginService.user.roles.indexOf('ROOT') > -1);
    }

    removeMFA(acc){
        const confirmed = confirm(`Certeza que deseja remover o autenticador do usuário ${acc.username}?`);

        if(confirmed){
            acc.mfa.active = false;
            this.accountService.removeMFA(acc).subscribe(resp => {
                let data = resp.json();
                this.toastr.success('Alteração salva.');
            })
        }
    }

    saveStyle(){
        if(!this.style.background &&  !this.style.logo){
            this.toastr.info('Selecione uma cor de fundo ou uma imagem de logo!');
            return;
        }
        //salvar no team
        this.accountService.saveCustomStyle(this.style).subscribe(async resp => {
            //this.loginService.user.team.company.style = this.style;
            this.loginService.user.team.style = this.style;

            this.toastr.success('Feito! reloge no sistema.');
        }, err => {
            this.toastr.error('Erro ao salvar.');
            console.log(err.json());
        });
    }

    edit(account: any) {
        let list = this.accounts;
        let teams = this.teams;
        this.dialog.open(EditAccountDialogComponent, {
            width: '800px',
            data: {
                account,
                list,
                teams
            }
        }).afterClosed().subscribe((action) => {
            this.findUsersNTeams();
        });
    }

    editTeam(team: any) {
        this.dialog.open(EditTeamDialogComponent, {
            width: '800px',
            data: {
                team,
                accounts: this.accounts,
                devices: this.devices 
            }
        }).afterClosed().subscribe((action) => {
            this.findUsersNTeams();
        });
    }

    create() {
        let list = this.accounts;
        let teams = this.teams;
        this.dialog.open(EditAccountDialogComponent, {
            width: '800px',
            data: {
                list,
                teams
            }
        }).afterClosed().subscribe((action) => {
            this.findUsersNTeams();
        });
    }

    createTeam() {
        this.dialog.open(EditTeamDialogComponent, {
            width: '800px',
            data: {
                accounts: this.accounts,
                devices: this.devices
            }
        }).afterClosed().subscribe((action) => {
            this.findUsersNTeams();
        });
    }

    remove(account: any){
        let result = confirm(`Deseja bloquear a conta ${account.username}`);

        if(result){
            this.delSub = this.accountService.deleteAccount(account._id).subscribe(async resp => {
                //this.accounts.splice(this.accounts.indexOf(account), 1);
                account.active = false;
                this.toastr.warning('Conta bloqueada!', '', {closeButton: true});
            }, err => {
                console.log(err.json());
            });
        }
    }

    unblock(account){
        let result = confirm(`Reativar a conta '${account.username}'?`);

        if(result){
            this.delSub = this.accountService.reactiveAccount(account._id).subscribe(async resp => {
                //this.accounts.splice(this.accounts.indexOf(account), 1);
                account.active = true;
                this.toastr.success('Conta desbloqueada!');
            }, err => {
                console.log(err.json());
            });
        }
    }

    removeTeam(team: any){
        console.log(team)
        let result = confirm(`Deseja desativar o time ${team.name}?`);

        if(result){
            if(team.allowedDevices.length){
                this.toastr.warning(`Remover devices antes da exclusão.`)
            } else {

                this.accountService.deactiveTeam(team._id).subscribe(async resp => {
                    this.teams.splice(this.teams.indexOf(team), 1);
                    this.toastr.warning('Time desativado!', '', {closeButton: true});
                }, err => {
                    console.log(err.json());
                });
            }
        }
    }

    createDevice(){
        this.dialog.open(SaveDeviceDialogComponent, {
            width: '800px',
            data: {}
        }).afterClosed().subscribe((action) => {
            this.findDevices();
        });
    }
    
    editDevice(device){
        this.dialog.open(SaveDeviceDialogComponent, {
            width: '800px',
            data: {
                device
            }
        }).afterClosed().subscribe((action) => {
            this.findDevices();
        });
    }

    createAction(){
        let dRef = this.dialog.open(ActionDialogComponent, {
            width: '800px',
            data: {
                devices: this.devices
            }
        });

        dRef.afterClosed().subscribe((action) => {
            this.findActions();
        });
    }

    rebootAction(dev){
        let result = confirm(`Soliticar 'REBOOT' do device '${dev.alias}'?`);
        let action = {
            command: 'reboot',
            device: dev
        }
        if(result){
            this.accountService.createAction(action).subscribe(async resp => {
                let data = await resp.json();
                console.log(data);
    
                //toast here
                this.toastr.success('Comando adicionado a fila!');
            });
        }
    }

    async onSelectUser(){
        var params = {
            from: this.filterFrom,
            to: this.filterTo,
            startHour: this.selectedStartHour,
            endHour: this.selectedEndHour,
        }
        this.trackingLogs = (await this.accountService.getUserTracking(this.selectedUser, params).toPromise()).json();
    }

    getAccounts(){
        if(this.loginService.isRoot()){
            return this.accounts;
        } else {
            return this.accounts.filter(u => !u.roles.includes('ROOT'));
        }
    }

    downloadCSV(){
        let toExport = JSON.parse(JSON.stringify(this.trackingLogs));
        toExport.map(l => l.details = JSON.stringify(l.details));
        let user = this.accounts.find(u => u._id == this.selectedUser);

        try {
            const csv = Papa.unparse(toExport);
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${user.username}.csv`;
            a.click();
            window.URL.revokeObjectURL(url);
            
          } catch (err) {
            console.error('Erro ao gerar CSV:', err);
          }
    }

    getTrackMsg(log){
        return log.details ? log.details.message : 'Sem detalhes de ação.';
    }

    showTrackDetails(log){
		this.detailModal.nativeElement.style.display = 'block';
        
        this.rawDatail = JSON.stringify(log, null, 2)
    }

    closeDialogOnOutsideClick(event: MouseEvent) {
        if (event.target === this.detailModal.nativeElement) {
            this.detailModal.nativeElement.style.display = 'none';
        }
    }

    closeDialog(element: any) {
        this.rawDatail = '';
		this.detailModal.nativeElement.style.display = 'none';
	}

    removeAction(action){
        let result = confirm(`Remover ação ${action.command} do device ${action.device.alias}?`);

        if(result){
            this.actSub = this.accountService.deleteAction(action._id).subscribe(async resp => {
                this.toastr.warning('Ação removida!', '', {closeButton: true});
                this.findActions();
            }, err => {
                console.log(err.json());
            });
        }
    }

    ngOnDestroy(): void {
        if(this.accountSub) this.accountSub.unsubscribe();
        if(this.delSub) this.delSub.unsubscribe();
        if(this.actSub) this.actSub.unsubscribe();

    }
}
