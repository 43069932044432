import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { URL_SERVER } from './../providers';

@Injectable()
export class InternalService {

	constructor(private http: Http) { }

	changeTeam(id) {
		return this.http.post(`${URL_SERVER}/services/user/team/change`, { teamId: id });
	}
	
	changeCompany(id) {
		return this.http.post(`${URL_SERVER}/superadmin/company/change`, { id });
	}

	listTeamsOfCompany() {
		return this.http.get(`${URL_SERVER}/admin/company/team/list`);
	}

	loadCompanies() {
		return this.http.get(`${URL_SERVER}/superadmin/company/short-list`);
	}

}
