import {  ChangeDetectorRef, Input, NgZone, Component, OnInit, OnDestroy, Inject, ViewChild, asNativeElements, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AccountsService } from './../accounts.service';
import { FormControl, Validators, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { UploadComponent } from '../../../util/upload/upload.component';
import { SelectListDialogComponent } from '../../pending-devices/edit-device-dialog/select-list-dialog/select-list.component';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../../login/login.service';

@Component({
  selector: 'edit-account-dialog',
  templateUrl: './edit-account-dialog.component.html',
  styleUrls: ['./edit-account-dialog.component.scss']
})
export class EditAccountDialogComponent implements OnInit, OnDestroy {

	@Input() elementDialog: any;
	@Input() deviceList = [] as any[];
	accountForm: FormGroup;
	respError = {message: '', type: ''};
	accSub:any;
	isLoading: boolean = false;


    constructor(
		private router: Router,
		private changeDetector: ChangeDetectorRef,
		private zone: NgZone,
        private loginService: LoginService,
		private toastr: ToastrService,
		private dialog: MatDialog, 
        private accService: AccountsService,
		public dialogRef: MatDialogRef<EditAccountDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any        
	) {
	}

    static MatchPassword(control: AbstractControl) {
        let password = control.get('password').value;
 
        let confirmPassword = control.get('confirmPassword').value;
 
        if(password != confirmPassword) {
            control.get('confirmPassword').setErrors( {ConfirmPassword: true} );
        } else {
            return null
        }
    }

	ngOnInit() {
		console.log(this.data)
		if(!this.data.account){
			this.data.account = {
				username:'',
				firstName:'',
				lastName:'',
				password:'',
				confirmPassword:'',
				email:'',
				team: {},
				isAdmin: false,
				isRoot: false,
			}
		} else {
			this.data.account.isAdmin = (this.data.account.roles.indexOf(`ADMIN`) > -1)
			this.data.account.isRoot = (this.data.account.roles.indexOf(`ROOT`) > -1)
		}
		// abrindo em editar ou criar assim semrpe tem a lista
		if(!this.data.account.teams){ this.data.account.teams = []; }
		if(this.data.account._id){
			this.data.account.teams = this.data.teams.filter(team => team.allowedUsers.some(allowedUser => allowedUser._id === this.data.account._id));
		}

		this.accountForm = new FormGroup({
		 	firstName: new FormControl({value: this.data.account.firstName}, Validators.required),
			lastName: new FormControl({value: this.data.account.lastName}, Validators.required),
			team: new FormControl({value: this.data.account.team.name}, Validators.required), 
			teams: new FormControl({value: this.data.account.teams}, Validators.required), 
		 	username: new FormControl({value: this.data.account.username}, Validators.required),
		 	password: new FormControl({value: this.data.account.password}),
		 	confirmPassword: new FormControl({value: this.data.account.confirmPassword}),
			email: new FormControl({value: this.data.account.email}, [Validators.required, Validators.email]),
			isAdmin: new FormControl({value: this.data.account.isAdmin}),
			isRoot: new FormControl({value: this.data.account.isRoot}),
        },{
            validators: EditAccountDialogComponent.MatchPassword
        });
    }

    saveAccount(){
		if(this.accountForm.valid){
			this.isLoading = true;
			
			let teamId = this.data.account.team._id;
			this.data.account.team = teamId;
			this.data.account.teams = this.data.account.teams.map(t => t._id);

			this.accSub = this.accService.saveAccount(this.data.account).subscribe(resp => {
				
				if(!this.data.list.find(i => i._id === resp.json()._id)){
					this.data.list.push(resp.json());
				}

				this.toastr.success('Dados Atualizados!', '', {closeButton: true});
				this.isLoading = false;

				this.dialogRef.close()
			}, err => {
				let resp = err.json();
				if(resp.type == 'username'){
					this.accountForm.get('username').setErrors({})
					this.respError.type = 'username';
				}
				if(resp.type == 'password'){
					this.accountForm.get('password').setErrors({});
					this.respError.type = 'password';
				}
				if(resp.type == 'team'){
					this.toastr.error('Selecione um time!', '', {closeButton: true});
				}
				
				this.isLoading = false;
				this.respError.message = resp.message;
			});
		}
	}
	
	list(list: any, type: any){
        this.dialog.open(SelectListDialogComponent, {
			width: '300px',
			height: '400px',
            data: {
				list,
				type,
				account: this.data.account
            }
        })
	}
	
	removeTeam(idx){
        this.data.account.teams.splice(idx, 1);
    }

	ngOnDestroy() {
		if(this.accSub) this.accSub.unsubscribe();
		this.changeDetector.detach();
	}
}
