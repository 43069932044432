import { Component, ChangeDetectorRef, Input, NgZone, OnInit, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';
import { DeviceAccessLogService } from './device-access-log.service';
import { DevicesService } from './../devices/devices.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResumeCompanyService } from '../admin/company/resume.service';
import { LoginService } from '../login/login.service';

@Component({
    selector: 'device-access-log',
    templateUrl: './device-access-log.component.html',
    styleUrls: ['./device-access-log.component.scss']
})

export class DeviceAccessLogComponent implements OnInit, OnDestroy {

    logFilter: String;
    accessLogs: any[] = [];
    range: any = 30;
    skip: any = 0;
    errorMessage: any;
    minDate: Date = new Date(2000, 0, 1);
    maxDate: Date = new Date(2120, 0, 1);
    filterFrom: Date;
	filterTo: Date;
    company: any = {plan: {}};
    startHour: any[];
    endHour: any[];
    selectedStartHour: any;
    selectedEndHour: any;

    @ViewChild('logErrorModal') logErrorModal: ElementRef;
    

    constructor(
        public logService: DeviceAccessLogService,
        private route: Router,
        public deviceService: DevicesService,
        private changeDetector: ChangeDetectorRef,
        private loginService: LoginService,
        private ResumeCompService: ResumeCompanyService,
		private toastr: ToastrService
    ){
        const currentDate: Date = new Date();
		this.minDate = new Date(currentDate.getTime() - (180 * 24 * 60 * 60 * 1000));
		this.maxDate = new Date(currentDate.setHours(23,59,59));
		
        this.filterTo = new Date(currentDate);
        this.filterTo.toLocaleDateString();
        this.filterFrom = new Date(currentDate.getTime() - (60 * 24 * 60 * 60 * 1000));
        this.filterFrom.toLocaleDateString();
    }

    ngOnInit(){
        this.selectedStartHour = '00'
        this.selectedEndHour = '23'

        this.startHour = []
        this.endHour = []
        for 
        (let i = 0; i < 24; i++) {
            this.startHour.push(`${i.toLocaleString('en-US',{ minimumIntegerDigits: 2, useGrouping: false })}`);
            this.endHour.push(`${i.toLocaleString('en-US',{ minimumIntegerDigits: 2, useGrouping: false })}`);
        }
        
        this.ResumeCompService.getCompanyResume(this.loginService.user.team.company._id)
        .subscribe(async (resp: any) => {
            this.company = await resp.json();
            console.log(this.company)
        });
        this.loadLogs(this.skip, this.range);
    }

    getUsageTime(log){
        console.log(log)
        if(!log.endTime || (log.endTime == log.startTime)){return 0}
        let seconds = Math.floor((new Date(log.endTime).getTime() - new Date(log.startTime).getTime())/1000);

        return this.loginService.calcDuration(seconds);
    }

    loadLogs(skip, limit, resetList = false){
        var params = {
            from: this.filterFrom,
            to: this.filterTo,
            startHour: this.selectedStartHour,
            endHour: this.selectedEndHour,
        }
        this.logService.getAccessLogs(skip, limit, params).subscribe(async (resp) => {
            let data = await resp.json();
            
            if(resetList){
                this.accessLogs = [];
            }

            this.accessLogs = this.accessLogs.concat(...data);
        }, (error) => {
            console.error(error);
        });
    }

    loadMore(){
        this.skip = this.skip + this.range;
        this.loadLogs(this.skip, this.range);
    }

    closeLogModal() {
        this.logErrorModal.nativeElement.style.display = 'none';
    }

    openLogErrorModel(logId){
        this.logErrorModal.nativeElement.style.display = 'block';
        this.errorMessage = 'Loading...';
        
        this.logService.getLogError(logId).subscribe(async resp => {
            const data = await resp.json()
            this.errorMessage = data.errorMessage;
        });
    }

    openReport(log){
        this.logService.getReport(log.sessionId).subscribe(async resp => {
            let data = await resp.json();

            if(data){
                this.route.navigate([`/report-suites/${data.execution}/${data.device}/${data._id}`]);
            }else{
                this.toastr.warning('Relatório não encontrado!', '', {closeButton: true});
            }
        });
    }

    findFiltered(){
        console.log(`Search with this params: ${this.filterFrom} - ${this.filterTo}`);
        this.skip = 0;
        this.loadLogs(this.skip,this.range, true)
    }

    clickOut(e){
        if(e.target.classList.contains('outside')){
            this.logErrorModal.nativeElement.style.display = 'none';
        }
    }

    calcPercent(total, used){
        return Math.round((used/total)*100);
    }

    getDate(date){
        return date ? new Date(date).toLocaleString('BR') : 'ultima atualização.';
    }

    ngAfterViewChecked(): void {
        this.changeDetector.detectChanges();
    }

    ngOnDestroy(){}
}