import { Injectable, Inject, EventEmitter, NgZone } from '@angular/core';
import { Http } from '@angular/http';
import { wsocketToken, URL_SERVER } from '../providers';
import { StageComponent } from './stage/stage.component';
import { DevicesService } from '../devices/devices.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ConnectService {

	activeDevices: ActiveDevice[] = [];
  	devicesToRetry: any[] = [];

	activeDevice: any = null;
	fullScreenDevice: any = null;
	activeEnhancedKeyboard: Boolean = true;
	screenReceiveEvent: EventEmitter<any> = new EventEmitter();
	logReceiveEvent: EventEmitter<any> = new EventEmitter();
	stageResizeEvent: EventEmitter<any> = new EventEmitter();
	deviceEvent: EventEmitter<any> = new EventEmitter();

	stageWidth: Number;
	stageHeight: Number;
	innerHeight: Number;

	constructor (
		private http: Http,
		private toastr: ToastrService,
		private zone: NgZone,
		private route: Router,
		private devicesService: DevicesService,
		@Inject(wsocketToken) private wsocket: any,
	) {

		this.wsocket.on('device-connecting', (args: any) => {
			console.log('connecting');
			const idx = this.activeDevices.findIndex( obj => obj.device._id === args.deviceId);
			//se o appium n subir ver timeout pra isso 15m?
			this.activeDevices[idx].timeoutAppiumStart = setTimeout(() => {
				//chamar função de release
				this.releaseConnection(this.activeDevices[idx].device, null, true);
				this.notifyUser('error', `Appium travou, tente novamente.`);
			},120000);
		});

		this.wsocket.on('device-connected', (args: any) => {
			this.zone.run(() => {
				const deviceObjectIndex = this.activeDevices.findIndex( obj => obj.device._id === args.deviceId);
				clearTimeout(this.activeDevices[deviceObjectIndex].timeoutAppiumStart);
				console.log('Timeout no connect')
				this.resetDeviceTimeout(deviceObjectIndex);

				this.activeDevices[deviceObjectIndex].status = 'CONNECTED';
				this.wsocket.emit('screen', args.deviceId);
			});
		});

    this.wsocket.on('device-disconnected', (data: any) => {
			//limpar timeout de inatividade
			console.log(data);
      const status = (data.status as string).toLowerCase();
      switch (status) {
        case 'error':
          const retry = confirm(
            `Não foi possível estabelecer conexão com o dispositivo ${data.deviceId}! Tentar restabelecer a conexão?`
          );
          this.releaseConnection({_id: data.deviceId}, retry);
        break;

				case 'retry':
							const dvc = this.devicesToRetry.find(device => device._id === data.deviceId);
							this.requestConnection(dvc);
				break;

				case 'reload':
					this.route.navigate(['/devices']);
					location.reload();
				break;
      }
    });

		this.wsocket.on('device-screen', (header: any, message: any) => {
			const deviceObject = this.activeDevices.find( obj => obj.device._id === header.deviceId);
			if (deviceObject) {
				deviceObject.screenResolution = header.resolution;
				deviceObject.screen = message;
				this.screenReceiveEvent.emit(deviceObject);
			} else {
				console.error('screen received for a undefined device');
			}
		});

		window.addEventListener('resize', () => {
			this.onWindowResize();
		});
		this.onWindowResize();
	}
	
	notifyUser(type, msg){
		if(type == 'error'){
			this.toastr.error(msg, 'Oops..', {tapToDismiss: true, closeButton: true});
		}
		if(type == 'warning'){
			this.toastr.warning(msg, 'Hey!', {tapToDismiss: true, closeButton: true});
		}
	}

	resetDeviceTimeout(deviceIndex) {
		if(this.activeDevices[deviceIndex]){
			let device = this.activeDevices[deviceIndex].device;

			clearTimeout(this.activeDevices[deviceIndex].timeoutMsg);
			clearTimeout(this.activeDevices[deviceIndex].timeoutDisc);
	
			this.activeDevices[deviceIndex].timeoutMsg = setTimeout(() => {
				console.log('Timeout 19m');
				this.notifyUser('warning', `Celular ${device.alias} inativo!`);
			},(59 * 60 * 1000)); //19 min
			
			this.activeDevices[deviceIndex].timeoutDisc = setTimeout(() => {
				console.log('Timeout 20m');
				this.releaseConnection(device);
				this.notifyUser('error', `Celular ${device.alias} desconectado por inatividade.`);
			},(60 * 60 * 1000)); //20 min
		}
	}

	onWindowResize() {
		let innerWidth = window.innerWidth;

		const offsetHeight = (64 + 40 + 70 + 15 + 40);
		const offsetToolbox = 75;

		let innerHeight = window.innerHeight - offsetHeight - offsetToolbox;

		this.innerHeight = innerHeight;
		console.log(innerHeight)

		let ratio = (innerWidth) / innerHeight ;

		if (innerWidth > innerHeight || ratio > 1) {
			this.stageWidth = innerHeight / ratio;
			this.stageHeight = innerHeight;
		} else {
			this.stageWidth = innerWidth;
			this.stageHeight = innerHeight * ratio;
		}

		this.stageResizeEvent.emit({
			width: this.stageWidth,
			height: this.stageHeight,
		});
	}

	sendAction(deviceId: string, requestActionId: string, action: string, value: any, args: any= {}) {
		return new Promise(async (resolve, reject) => {
			this.wsocket.once('response_' + requestActionId, (response: any) => {
				
				const deviceObject = this.activeDevices.find( obj => obj.device._id === deviceId);
				if (deviceObject.isRecording && action == 'live') {
					this.wsocket.emit('livereport', deviceObject.livereportId, action, value, deviceObject.screen);	
				}
				
				resolve(response)
			});
			this.wsocket.emit('action', action, value, deviceId, {requestActionId: requestActionId});
			//Resetar timeout de inatividade
			const deviceObjectIndex = this.activeDevices.findIndex( obj => obj.device._id === deviceId);
			this.resetDeviceTimeout(deviceObjectIndex);
		});
	}

	runCode(deviceId: string, requestActionId: string, code: string, testSuite: any) {
		return new Promise((resolve, reject) => {
			this.wsocket.once('response_' + requestActionId, (response: any) => resolve(response));
			this.wsocket.emit('code', code, deviceId, {requestActionId: requestActionId}, testSuite);
			//Resetar timeout de inatividade
			const deviceObjectIndex = this.activeDevices.findIndex( obj => obj.device._id === deviceId);
			this.resetDeviceTimeout(deviceObjectIndex);
		});
	}

	private onCheck(connectionEntry: any) {
		this.http.post(`${URL_SERVER}/services/device/ticket/check`, {token: connectionEntry.ticket.token, deviceId: connectionEntry.device._id}).subscribe(
			(checkResponse) => {
				const check = checkResponse.json();
				connectionEntry.ticket.position = check.position;

				//ver uum jeito de tirar o clear interval por outor luagr pro desconect
				if (connectionEntry.ticket.position === 0) {
					clearInterval(connectionEntry.ticket.interval);
					this.wsocket.emit('device-connect', connectionEntry.device._id, connectionEntry.ticket.token);
				}
			},
			(error) => {
				clearInterval(connectionEntry.ticket.interval);
				console.log(error);
			}
		);
	}

	requestConnection(device: any) {

		const connectionEntry = {
			paused: false,
			device: device,
			screen: undefined,
			screenResolution: undefined,
			appRunning: false,
			replicate: false,
			stage: null,
			iOSorientation: '',
			stats: [],
			// status: 'CONNECT_REQUEST',
			status: 'TICKET_REQUEST',
			ticket: undefined,
			log: {
				appium: [],
				device: [],
			},
			livereportId: '',
			isRecording: false,
			isScreenRecording: false,
			timeoutMsg: undefined,
			timeoutDisc: undefined,
			timeoutAppiumStart: undefined
		};

		this.activeDevices.unshift(connectionEntry);
    	const index = this.devicesToRetry.findIndex(d => d._id === device._id);
		if (index >= 0) {
			this.devicesToRetry.splice(index, 1);
		}

		this.http.post(`${URL_SERVER}/services/device/ticket/alloc`, {deviceId: device._id}).subscribe(
			(allocResponse) => {

				const ticket = allocResponse.json();

				connectionEntry.ticket = {
					position: ticket.position,
					token: ticket.token,
				};

				if (connectionEntry.ticket.position > 0) {
					connectionEntry.ticket.interval = setInterval((cEntry) => {
						this.onCheck(cEntry);
					}, 5000, connectionEntry);
				} else {
					this.wsocket.emit('device-connect', device._id, ticket.token);
				}
			},
			(error) => {
				console.log(error);
			},
		);

		//
	}

	requestReconnect(device: any) {
		const index = this.activeDevices.filter(dev => dev.device._id === device._id);
		if (!index[0]) {
			this.activeDevices.unshift({
				paused: false,
				device: device,
				screen: undefined,
				screenResolution: undefined,
				appRunning: false,
				replicate: false,
				stage: null,
				stats: [],
				iOSorientation: '',
				status: 'CONNECTED',
				log: {
					appium: [],
					device: [],
				},
				ticket: {},
				livereportId: '',
				isRecording: false,
				isScreenRecording: false,
				timeoutMsg: undefined,
				timeoutDisc:undefined,
				timeoutAppiumStart: undefined
			});
		}
	}

	rotateScreen(device: any){
		this.wsocket.emit('screen-rotate', device._id);
	}

  	releaseConnection(device: any, retry: boolean = false, force: boolean = false) {
		console.log('Force disconect')
		let releaseToken;
		const index = this.activeDevices.findIndex(itDevice => itDevice.device._id === device._id);

		if (retry) {
			this.devicesToRetry.push(this.activeDevices[index].device);
		}
		if(this.activeDevices[index] && this.activeDevices[index].ticket){
			releaseToken = this.activeDevices[index].ticket.token;
			clearInterval(this.activeDevices[index].ticket.interval)
		}

		if(this.activeDevices[index] && this.activeDevices[index].isRecording){
			this.devicesService.finishLiveReport(this.activeDevices[index].livereportId)
			.subscribe(async resp => {
				console.log('finishing live report')
			})
		}

		if(force || this.activeDevices[index].status != "TICKET_REQUEST"){
			console.log('FORCE DISCONNECT');
			const isAutomated: boolean = device.owner && device.owner.type === 'automated';

			this.wsocket.emit('device-disconnect', device._id, isAutomated, retry, (releaseToken || ''));
		}else{
			this.http.post(`${URL_SERVER}/services/device/ticket/dealloc`, {token: releaseToken, deviceId: device._id}).subscribe(async data => {
				let resp = await data.json();
				console.log(resp);
			});
		}
		clearTimeout(this.activeDevices[index].timeoutMsg);
		clearTimeout(this.activeDevices[index].timeoutDisc);
		if(this.activeDevice && this.activeDevice.device._id == this.activeDevices[index].device._id){
			this.activeDevice = null;
		}
		this.activeDevices.splice(index, 1);
	}

	releaseAutomated(device: any){
		const index = this.activeDevices.findIndex(itDevice => itDevice.device._id === device._id);
		this.activeDevices.splice(index, 1);
	}

	cleanDeviceStatus(device: any) {
		const index = this.activeDevices.findIndex(itDevice => itDevice.device._id === device._id);
    	this.activeDevices.splice(index, 1);
	}

	requestScreen(deviceId: string, header?: any) {
		if (!header) {
			header = {};
		}
		const deviceObject = this.activeDevices.find( obj => obj.device._id === deviceId);
		if (deviceObject.status === 'CONNECTED') {
			this.wsocket.emit('screen', deviceId, header);
		}
	}

	requestLog(deviceId: string, requestActionId: string): Promise<any> {
		const deviceObject = this.activeDevices.find( obj => obj.device._id === deviceId);
		if (deviceObject.status === 'CONNECTED') {
			return new Promise((resolve, reject) => {
				this.wsocket.once('response_' + requestActionId, (response: any) => resolve(response));
				this.wsocket.emit('action', 'log', '', deviceId, {requestActionId: requestActionId});
			});
		}
		return null;
	}

	requestStats(deviceId: string, requestActionId: string): Promise<any> {
		const deviceObject = this.activeDevices.find( obj => obj.device._id === deviceId);
		if (deviceObject && deviceObject.status === 'CONNECTED') {
			return new Promise((resolve, reject) => {
				this.wsocket.once('response_' + requestActionId, (response: any) => resolve(response));
				this.wsocket.emit('action', 'stats', '', deviceId, {requestActionId: requestActionId});
			});
		}
		return null;
	}
}

export class ActiveDevice {
	paused: Boolean;
	device: any;
	screen: any;
	screenResolution: any;
	appRunning: Boolean;
	iOSorientation: String;
	status: String;
	replicate: Boolean;
	stage: StageComponent;
	stats: any[];
	log: {
		device: any[],
		appium: any[],
	};
	livereportId: any;
	isRecording: any;
	isScreenRecording: any;
	ticket: any;
	timeoutMsg: any;
	timeoutDisc: any;
	timeoutAppiumStart: any;
}
