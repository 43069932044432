import { Component, Input, Inject, ChangeDetectorRef, NgZone, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { ConnectService } from '../../connect.service';
import { windowToken, wsocketToken } from '../../../providers';
import { UploadComponent } from '../../../util/upload/upload.component';
import { AppsService } from '../../../apps/apps.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tooltab-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit, OnDestroy {

//this.run({browser: true});
	activity: string;
	package: string;
	bundleId: string;
	@Input() deviceObject: any;
	@ViewChild('upload') upload: UploadComponent;
	@ViewChild('uploadModal') uploadModal: ElementRef;
	@ViewChild('sendActivityModal') sendActivityModal: ElementRef;
	@ViewChild('sendBundleIdModal') sendBundleIdModal: ElementRef;

	apps: any[] = [];
	images: any[] = [];
	documents: any[] = [];
	uploadSubscr: any;
	working:any = false;
	loading:any = false;
	errorMessage:string;
	flagList: string;

	public run(app: any) {
		const ref = this;
		const actionRequestId = this.appService.uuid();
		//travar timeout aqui
		let index = this.connectService.activeDevices.findIndex(itDevice => itDevice.device._id === ref.deviceObject.device._id);
		clearTimeout(this.connectService.activeDevices[index].timeoutMsg);
		clearTimeout(this.connectService.activeDevices[index].timeoutDisc);

		if(!app.url){
			this.connectService.sendAction(
				this.deviceObject.device._id,
				actionRequestId, 'applaunch',
				app
			).then((data: any) => {
				if(index >= 0){
					this.connectService.resetDeviceTimeout(index);
				}
				this.working = false;
				if (data.data.status === 'RUNNING') {

					this.deviceObject.appRunning = true;
					this.connectService.requestScreen(this.deviceObject.device._id, { ratio: 0.3, quality: 40 });

				}else if (data.data.status === 'ERROR') {

					const retry = confirm(`OPS! Ocorreu um erro ao inicializar: \r\n ${data.data.message.data} \r\n \r\n Deseja tentar novamente?`);
					if (retry) {
						ref.run(app);
					} else {
						ref.connectService.releaseConnection(ref.deviceObject.device);
					}
				} else {
					alert('Unknown server side error.');

					ref.connectService.releaseConnection(ref.deviceObject.device);
				  }
			});
		}else{
			this.toastr.info('Instalando...', '', {closeButton: true});
			
			this.connectService.sendAction(
				ref.deviceObject.device._id,
				actionRequestId, 'live', {
					action: 'install',
					reference: app.url,
			}).then((result: any) => {
				// console.log(result.data);
				if (result.data.status === 'ERROR') {
					this.toastr.error('Erro ao instalar aplicativo.', 'Error!', {closeButton: true});
				}else{
					this.toastr.success('App instalado.', 'Boa!', {closeButton: true});
				}
			});
		}
	}

	constructor(
		private router: Router,
		public connectService: ConnectService,
		private appService: AppService,
		private appsService: AppsService,
		private zone: NgZone,
		private toastr: ToastrService,
		private changeDetector: ChangeDetectorRef,
		@Inject(wsocketToken) private wsocket: any,
		@Inject(windowToken) private window: any,
	) {
	}

	sendFile(url){
		const actionRequestId = this.appService.uuid();
		this.toastr.success(`Enviando arquivo.`)

		this.connectService.sendAction(
			this.deviceObject.device._id,
			actionRequestId, 'live', {
				action: 'downloadFile',
				reference: url,
		}).then((result: any) => {
			// console.log(result.data);
			if (result.data.status === 'ERROR') {
				this.toastr.error('Erro ao baixar arquivo.', 'Error!', {closeButton: true});
			}else{
				this.toastr.success('Arquivo salvo no device.', 'Boa!', {closeButton: true});
			}
		});
	}

	sendInfo() {
		if (this.deviceObject.device.OS.platform.toLowerCase() === 'android') {
			if (this.activity && this.package) {
				this.run({appActivity: this.activity, appPackage: this.package});
			}
		} else if (this.deviceObject.device.OS.platform.toLowerCase() === 'ios' && this.bundleId) {
			this.run({bundleId: this.bundleId});
		}

		this.errorMessage = 'É necessario que todos os campos estejam preenchidos'
		setTimeout(()=>this.errorMessage='',3000)
		this.package = ""
		this.activity = ""
		this.bundleId = ""
	}

	openDialog(element: any) {
		element.style.display = 'block';
	}

	closeDialog(element: any) {
		element.style.display = 'none';
	}

	appLoaded(newAppLoaded){
		this.appsService.listApps().subscribe(
			(result: any) => {
				this.apps = result.json();
			},
			(error: any) => {
				console.log(error.json().message);
			},
		);
	}
	ngOnInit() {
		try {
			this.flagList = 'apps'
			this.loadData();
			let ref = this;

			this.uploadSubscr = this.upload.uploadEvent.subscribe((val: any) => {
				// this.apps.push(val);
				ref.loadData();
			});
		} catch (error) {
			console.log(`Erro ao listar apps ${error.message}`);
		}
	}


	async loadData() {
		this.apps = (await this.appsService.listApps().toPromise()).json();
		this.images = (await this.appsService.listImages().toPromise()).json();
		this.documents = (await this.appsService.listDocs().toPromise()).json();
	}

	ngAfterViewInit() {
		this.wsocket.on('script-output', (data: any) => {

      try {
		//resetar timeout aqui
		//travar timeout aqui
		const index = this.connectService.activeDevices.findIndex(itDevice => itDevice.device._id === this.connectService.activeDevice.device._id);
		this.connectService.resetDeviceTimeout(index);


		const resp = JSON.parse(data.value);

  			if (resp.type && resp.type === 'install') {
  				// noty success or error
  				this.loading = false;

  				if (resp.msg === 'error') {
  					this.toastr.error('Erro ao instalar aplicativo.', 'Error!', {closeButton: true});
  				}else{
					this.toastr.success('App instalado.', 'Boa!', {closeButton: true});
				}
  			}
      } catch (e) {
        // do nothing
      }
		});
	}

	ngOnDestroy() {
		this.uploadSubscr.unsubscribe();
	}

}
