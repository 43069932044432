import { Component, Inject, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DevicesService, DeviceFilter } from '../devices/devices.service';
import { AppsService } from '../apps/apps.service';
import { ConnectService } from '../connect/connect.service';
import { windowToken } from '../providers';
import { UploadComponent } from '../util/upload/upload.component';
import { ToastrService } from 'ngx-toastr';


@Component({
	selector: 'app-apps',
	templateUrl: './apps.component.html',
	styleUrls: ['./apps.component.scss']
})

export class AppsComponent implements OnInit {

	deviceObject: any;
	apps: any[] = [];
	images: any[] = [];
	documents: any[] = [];
	app: any;

	@ViewChild('confirmDelModal') confirmDelModal: ElementRef;

	osFilter: any[] = [];

	@ViewChild('upload') uploadComp: UploadComponent;
	@ViewChild('uploadImg') uploadImgComp: UploadComponent;
	@ViewChild('uploadDoc') uploadDocComp: UploadComponent;
	uploadSubscr: any;
	uploadPlatform: any;
	upload: {
		platform: any,
	} = {
			platform: null,
		};

	deviceOSList = [{ platform: 'android' }, { platform: 'ios' }];

	constructor(
		private toastr: ToastrService,
		private appsService: AppsService,
		@Inject(windowToken) private wsocket: any
	) { }

	ngOnInit() {
		try {
			this.loadData();
			let ref = this;

			this.uploadComp.uploadEvent.subscribe((val: any) => ref.loadData());
			this.uploadImgComp.uploadEvent.subscribe((val: any) => ref.loadData());
			this.uploadDocComp.uploadEvent.subscribe((val: any) => ref.loadData());
		} catch (error) {
			console.log(`Erro ao listar apps ${error.message}`);
		}
	}

	onLoad() {
		console.log(`load tab images`)
	}

	filteredApps(): any[] {
		if (this.osFilter.length === 0) {
			return this.apps;
		} else if (this.osFilter.length === this.deviceOSList.length) {
			return this.apps;
		} else {
			const filtered: any[] = [];
			this.apps.forEach((app) => {
				if (this.osFilter.indexOf(app.OS.platform) > -1) {
					filtered.push(app);
				}
			});
			return filtered;
		}
	}

	uploadParams() {
		if (this.uploadPlatform) {
			return { type: this.uploadPlatform, deviceOS: this.uploadPlatform };
		} else {
			return null;
		}
	}

	copyImgURL(file: any) {
		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = file.url;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);

		this.toastr.success('Copiado!');
	}

	copyURL(file: any) {
		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = file.resource.url;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);

		this.toastr.success('Copiado!');
	}

	onChangeFilter(event: any, type: string, value: any) {
		const index = this.osFilter.indexOf(value);
		if (event.checked) {
			if (index === -1) {
				this.osFilter.push(value);
			}
		} else {
			if (index > -1) {
				this.osFilter.splice(index, 1);
			}
		}
	}

	onClickDelete(app: any) {
		this.app = app;
		this.openModal(this.confirmDelModal.nativeElement);
	}
	
	async onClickDeleteImgDoc(item: any) {
		await this.appsService.deleteResource(item._id).toPromise();

		this.toastr.success('Deletado');
		this.loadData();
	}

	async loadData() {
		this.apps = (await this.appsService.listApps().toPromise()).json();
		this.images = (await this.appsService.listImages().toPromise()).json();
		this.documents = (await this.appsService.listDocs().toPromise()).json();
	}

	delete() {
		this.appsService.delete(this.app._id).subscribe(
			(result: any) => {
				this.closeModal(this.confirmDelModal.nativeElement);
				this.apps = [];
				this.ngOnInit();
			},
			(error: any) => {
				console.log(error)
				// this.errorMessage = error.json().message;
			}
		);
	}

	async renameImgDoc(resource){
		let newName = prompt('Insia o novo nome');

		if (newName && newName.length) {
			resource.name = newName;

			let data = (await this.appsService.renameResource(resource).toPromise()).json();
			console.log(data)
			this.toastr.success('Alterações salvas.');

		}
	}

	editName(app) {
		const newName = prompt('Insia o nome do APP');

		if (newName && newName.length) {
			console.log(newName);
			app.name = newName;

			this.appsService.renameApp(app).subscribe(resp => {
				let data = resp.json();

				console.log(data)
				this.toastr.success('Alterações salvas.');
			})

		}
	}

	openModal(modal: any) {
		modal.style.display = 'block';
	}

	closeModal(modal: any) {
		modal.style.display = 'none';
	}

}
